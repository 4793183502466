import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {Scenario} from '../../../../../models/scenario.model';
import {ScenarioGroup} from '../../../../../models/scenario-group.model';
import {ScenarioGroupService} from '../../../../../services/scenario-group.service';
import {ScenarioService} from '../../../../../services/scenario.service';
import {SkuConfig} from '../../../../../models/sku-config.model';
import {ActivatedRoute} from '@angular/router';
import {
    displayPriceCellValidator, featureAndDisplayPriceCellValidator,
    featurePriceCellValidator,
    percentageCellValidator,
    priceCellValidator, promoPriceCellValidator, sizeCellValidator,
    specialPromoPriceCellValidator
} from '../../../../../utils/sku-config.validator';
import {isEmpty} from '../../../../../utils/object-utils';
import {Subject, Subscription} from 'rxjs';
import {SkuGroupService} from '../../../../../services/sku-group.service';
import {forkJoin} from 'rxjs';
import {AppConstantsService} from '@app/services/app-constants.service';
import {UserConfigurationsService} from '@app/services/user-configurations.service';
import {UserConfigurations} from '@app/models/user-configurations.model';
import {SkuConfigTableCellRenderers} from '@app/utils/sku-config-table-cell-renderers';
import {GeneralSettingService} from '@app/services/general-setting.service';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import {DropdownData} from '@app/components/dropdown/dropdown.data.model';
import {GeneralSetting} from '@app/models/general-setting.model';
import {ModelRunSku} from '@app/models/model-run-sku.model';
import {SimulationRunInput} from "@app/models/simulation-run-input.model";
import {SimulationRunResult} from "@app/models/simulation-run-result.model";
import {SimulationRunResultService} from "@app/services/simulation-run-result.service";
import {SimulationRunInputService} from "@app/services/simulation-run-input.service";
import {MetaData} from '@app/models/meta-data.model';
import {SkuGroup} from "@app/models/sku-group.model";
import {ModelRun} from "@app/models/model-run.model";
import {ModelRunService} from "@app/services/model-run.service";
import {Segment} from "@app/models/segment.model";
import {MetaDataService} from "@app/services/meta-data.service";

const LOW_POPULATION_THRESHOLD = 75;

@Component({
    selector: 'app-compare-scenarios',
    templateUrl: './compare-scenarios.component.html',
    styleUrls: ['./compare-scenarios.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompareScenariosComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input() modelRunSkus: Array<ModelRunSku>;
    @Input() metaData: MetaData;
    @Input() generalSetting: GeneralSetting;
    @Input() scenarios: Scenario[];
    @Input() scenarioGroups: ScenarioGroup[];
    @Input() selectedScenarioId: string;
    @Input() showCheckbox: boolean;
    @Input() nSize: number;
    @Input() skuGroups: Array<SkuGroup>;
    @Input() filters: Array<DropdownData<string>>;
    @Input() lowPopulation = false;
    @Input() lowPopulationMsgType = 'warn';
    @Input() lowPopulationMsg: string;
    @Output() clicked = new EventEmitter();
    dropdownChange: Subject<any> = new Subject();
    selectScenarioGroupsData: any[];
    selectReferenceScenarioData: any;
    multiSelectScenarioGroupsData: any[];
    multiSelectReferenceScenarioData: any;
    selectSearchValue = '';
    multiSelectSearchValue = '';
    selectScenarioId: any;
    multiSelectedScenarioIds: Array<string> = [];
    inputScenariosList: Array<string> = [];
    selectedInputScenarioId: string;
    inputsLabel: string;
    enableCompare = false;
    runSkuConfigs: Array<SkuConfig>;
    scenarioSkuConfigs: Record<string, Array<SkuConfig>>;
    scenarioInputs: Record<string, Array<SimulationRunInput>>;
    scenarioResults: Record<string, Array<SimulationRunResult>>;
    isSingleComparison: boolean;
    hasScenarioForInputsSelected: boolean;
    compareTableSettings: any = {
        columns: [],
        columnHeaders: [],
        groupHeaders: [],
        allowFiltersForHeaders: [1],
        groupColumnStartIndex: 0,
        inputsColumnStartIndex: 0,
        outPutColumnStartIndex: 0,
        subHeaders: [],
    };
    visibleColumns: any;
    conditionalColumns: any;
    hotData: any;
    outPutFieldsTotal: any;
    cellRenderers: SkuConfigTableCellRenderers;
    isExportButtonDisable = true;
    activeSkuGroupId = '';
    disablePopulationFilters = false;
    hasUnSelectedNonSampleFilter: boolean;
    modelRun : ModelRun;
    segments: Segment[];
    userConfigurations : UserConfigurations;
    absoluteSelected = false;
    outputsFilteredOnNonSampleFilterToggleTooltip: string;
    subscriptions: Subscription;
    totalNSize: number;
    resetPopulationFiltersStatus = false;
    hidePopulationFiltersReset = false;
    filterChanged = false;

    get projectId(): number {
        return this.metaData.projectId;
    }

    get modelRunId(): string {
        return this.metaData.modelRunId;
    }

    constructor(private scenarioGroupService: ScenarioGroupService,
                private scenarioService: ScenarioService,
                private route: ActivatedRoute,
                private cdRef: ChangeDetectorRef,
                private skuGroupService: SkuGroupService,
                private appConstantsService: AppConstantsService,
                private userConfigurationsService: UserConfigurationsService,
                private generalSettingService: GeneralSettingService,
                private uiBlockerService: UiBlockerService,
                private snackBarService: SnackBarService,
                private simulationRunInputService: SimulationRunInputService,
                private simulationRunResultService: SimulationRunResultService,
                private modelRunService: ModelRunService,
                private metaDataService: MetaDataService) {
    }

    ngOnInit(): void {
        this.hotData = [];
        this.scenarioSkuConfigs = {};
        this.scenarioInputs = {};
        this.scenarioResults = {};
        this.multiSelectedScenarioIds = [this.selectedScenarioId];
        this.subscriptions = new Subscription();

        forkJoin([...this.multiSelectedScenarioIds.map(it => this.simulationRunInputService.fetchAll(this.projectId, this.modelRunId, it))]).subscribe((scenarioInputs: Array<Array<SimulationRunInput>>) => {
            this.scenarioInputs = scenarioInputs.reduce((acc, inp) => {
                acc[inp[0].scenarioId] = inp;
                return acc;
            }, {});

            this.prepareScenarioGroups('', [this.selectedScenarioId]);
            this.prepareMultiSelectScenarioGroups('', [],
                [{
                    scenarioId: this.selectedScenarioId,
                    scenarioGroupId: this.scenarioGroupService.findScenarioGroupByScenarioId(this.selectedScenarioId)
                }]);
            this.inputsLabel = 'None';
            this.runSkuConfigs = this.scenarioService.cachedScenarioSkuConfigs[this.selectedScenarioId].map(x => {
                return Object.assign({}, x);
            });
            this.outPutFieldsTotal = new Map();
            this.isSingleComparison = true;
            this.hasScenarioForInputsSelected = false;
            this.modelRun = this.modelRunService.activeModelRun;
            this.segments = this.route.snapshot.data.segments;
            const selectedSegments = this.modelRunService.getUserRunLevelSelectedSegments(this.modelRun);
            this.hasUnSelectedNonSampleFilter = this.modelRunService.unSelectedNonSameFilterExists(this.modelRunService.getPopulationFilters(selectedSegments, this.metaData, this.segments));
            this.cellRenderers = this.generalSettingService.createSkuConfigTableCellRenderersInstance(this.generalSetting);
            this.initializeColumns();
            this.reloadData();
            if (this.segments.length) {
                this.subscriptions.add(this.modelRunService.getNSize(this.projectId, this.modelRunId, this.segments.map(it => it.segment)).subscribe((data: any) => {
                    this.totalNSize = data.nSize;
                }));
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    prepareScenarioGroups(filterString, disabledScenarios: string[] = []): void {
        const dropDownScenarioGroups = this.scenarioGroupService.prepareScenarioGroups(this.scenarios, this.scenarioGroups,
            filterString, disabledScenarios);
        this.selectReferenceScenarioData = dropDownScenarioGroups.referenceScenario;
        this.selectScenarioGroupsData = dropDownScenarioGroups.scenarioGroupsData;
    }

    prepareMultiSelectScenarioGroups(filterString, disabledScenarios: string[] = [], multiSelectDropDownData: any = []): any {
        const dropDownScenarioGroups = this.scenarioGroupService.prepareScenarioGroups(this.scenarios, this.scenarioGroups,
            filterString, disabledScenarios, multiSelectDropDownData);
        this.multiSelectReferenceScenarioData = dropDownScenarioGroups.referenceScenario;
        this.multiSelectScenarioGroupsData = dropDownScenarioGroups.scenarioGroupsData;
    }

    toggleCompareModel($event): void {
        this.clicked.emit(true);
    }

    multiSelectChanged(event): void {
        const selectedScenarios = event.map((dropDownData) => {
            return dropDownData.scenarioId;
        });
        this.inputsLabel = 'None';
        this.hasScenarioForInputsSelected = false;
        this.inputScenariosList = [];
        this.multiSelectedScenarioIds = selectedScenarios;
        this.prepareScenarioGroups(this.selectSearchValue, this.multiSelectedScenarioIds);
        this.prepareMultiSelectScenarioGroups(this.multiSelectSearchValue, [this.selectScenarioId], event);
        this.checkCompareEnable();
    }

    selectChanged(selectedScenarioId: string): void {
        this.inputsLabel = 'None';
        this.hasScenarioForInputsSelected = false;
        this.inputScenariosList = [];
        this.selectScenarioId = selectedScenarioId;
        this.prepareMultiSelectScenarioGroups(this.multiSelectSearchValue, [this.selectScenarioId]);
        this.checkCompareEnable();
    }

    multiSelectFilter(searchValue): void {
        this.multiSelectSearchValue = searchValue;
        this.prepareMultiSelectScenarioGroups(this.multiSelectSearchValue, [this.selectScenarioId]);
    }

    selectFilter(searchValue): void {
        this.selectSearchValue = searchValue;
        this.prepareScenarioGroups(this.selectSearchValue, this.multiSelectedScenarioIds);
    }

    compareScenarios(): void {
        const projectId = this.metaData.projectId;
        const modelRunId = this.metaData.modelRunId;
        this.uiBlockerService.block();
        this.isExportButtonDisable = false;
        this.inputScenariosList = [];
        this.multiSelectedScenarioIds.forEach(scenarioId => {
            this.inputScenariosList.push(scenarioId);
        });
        this.inputScenariosList.push(this.selectScenarioId);
        this.enableCompare = false;
        const userRunLevelSelectedSegments = this.getSelectedSegments().join(',');

        this.userConfigurations = this.userConfigurationsService.getUserConfigurationsByType(this.appConstantsService.compareMultiSkuConfigColumnChooserConfigType);
        if(!this.isSingleComparison && this.userConfigurations){
                this.userConfigurations.configurations['hideOutputs'] =[];
                this.userConfigurationsService.updateUserConfiguration(this.userConfigurations).subscribe(userConfigurations => {
                    this.userConfigurationsService.userConfigurations = userConfigurations;
                    this.scenarioService.simulateScenarios(projectId, modelRunId, this.inputScenariosList, userRunLevelSelectedSegments).subscribe(() => {
                        this.userConfigurationsService.getUserConfigurations(this.projectId, this.modelRunId).subscribe();
                        forkJoin(this.inputScenariosList.map((it) => this.simulationRunInputService.fetchAll(projectId, modelRunId, it))).subscribe((scenarioInputs: Array<Array<SimulationRunInput>>) => {
                            scenarioInputs.forEach((inputs) => {
                                this.scenarioInputs[inputs[0].scenarioId] = inputs;
                            });
                            forkJoin(this.inputScenariosList.map((it) => this.simulationRunResultService.fetchAll(projectId, modelRunId, it))).subscribe((scenarioResults: Array<Array<SimulationRunResult>>) => {
                                scenarioResults.forEach((results) => {
                                    this.scenarioResults[results[0].scenarioId] = results;
                                });
                                this.reloadData();
                                this.uiBlockerService.unblock();
                            });
                        });
                    });
                });
        }else{
            this.scenarioService.simulateScenarios(projectId, modelRunId, this.inputScenariosList, userRunLevelSelectedSegments).subscribe(() => {
                this.userConfigurationsService.getUserConfigurations(this.projectId, this.modelRunId).subscribe();
                forkJoin(this.inputScenariosList.map((it) => this.simulationRunInputService.fetchAll(projectId, modelRunId, it))).subscribe((scenarioInputs: Array<Array<SimulationRunInput>>) => {
                    scenarioInputs.forEach((inputs) => {
                        this.scenarioInputs[inputs[0].scenarioId] = inputs;
                    });
                    forkJoin(this.inputScenariosList.map((it) => this.simulationRunResultService.fetchAll(projectId, modelRunId, it))).subscribe((scenarioResults: Array<Array<SimulationRunResult>>) => {
                        scenarioResults.forEach((results) => {
                            this.scenarioResults[results[0].scenarioId] = results;
                        });
                        this.reloadData();
                        this.uiBlockerService.unblock();
                    });
                });
            });
        }
    }

    getScenario(scenarioId): Scenario {
        return this.scenarioService.getScenario(scenarioId);
    }

    changeComparisonInputs(scenarioId): void {
        this.selectedInputScenarioId = scenarioId;
        if (scenarioId === '0') {
            this.inputsLabel = 'None';
            this.hasScenarioForInputsSelected = false;
        } else {
            this.inputsLabel = scenarioId ? this.getScenario(scenarioId).name : 'None';
            this.hasScenarioForInputsSelected = true;
        }
        this.reloadData();
    }

    checkCompareEnable(): void {
        this.enableCompare = (this.multiSelectedScenarioIds.length > 0 && this.selectScenarioId) || (this.multiSelectedScenarioIds.length > 0 && this.selectScenarioId && this.filterChanged);
        this.isExportButtonDisable = !this.enableCompare;
        this.isSingleComparison = this.multiSelectedScenarioIds.length === 1;
        this.reloadData(true);
    }

    constructMandatoryColumnHeaders(): void {
        let columnCount = 0;
        this.compareTableSettings.groupHeaders.push({
            label: '<span class="nested-header-font hide-options">ITEMS</span>',
            colspan: 2
        });
        Object.keys(this.visibleColumns).forEach((key) => {
            if (this.metaData.inputConfigurations[key]) {
                this.compareTableSettings.columnHeaders.push(this.metaData.inputConfigurations[key]['displayName']);
            } else {
                this.compareTableSettings.columnHeaders.push(this.visibleColumns[key]['displayName']);
            }
            columnCount = columnCount + 1;
            this.compareTableSettings.columns.push(this.visibleColumns[key]);
        });
    }

    constructColumnHeaders(): void {
        const configType = this.appConstantsService.compareSkuConfigColumnChooserConfigType;
        let columnChooserConfigurations = this.userConfigurationsService.getUserConfigurationsByType(configType);
        columnChooserConfigurations = columnChooserConfigurations ? columnChooserConfigurations : new UserConfigurations();
        columnChooserConfigurations.configurations = columnChooserConfigurations.configurations ? columnChooserConfigurations.configurations : {
            hideGroups: [],
            hideInputs: [],
            hideOutputs: []
        };
        let inputsColumnCount = 0;
        let outputColumnCount = 0;
        if (this.hasScenarioForInputsSelected) {
            inputsColumnCount = this.constructPriceInputColumnHeaders(inputsColumnCount, columnChooserConfigurations.configurations);
            inputsColumnCount = this.constructPromoInputColumnHeaders(inputsColumnCount, columnChooserConfigurations.configurations);
            if (inputsColumnCount > 0) {
                const inputColumnChooserButton = `<span class="menu-btn padding-left-1 col-chooser-btn sif sif-column-chooser inputs"></span>`;
                this.compareTableSettings.groupHeaders.push({
                    label: `<span class="nested-header-font">INPUTS ${inputColumnChooserButton}</span>`,
                    type: 'INPUTS',
                    colspan: inputsColumnCount
                });
            }
            this.compareTableSettings.inputsColumnStartIndex = 2;
        } else {
            this.compareTableSettings.inputsColumnStartIndex = -1;
        }
        if ( this.multiSelectedScenarioIds.length > 0 && this.selectScenarioId && !this.enableCompare) {
            outputColumnCount = this.constructOutputColumnHeaders(outputColumnCount, columnChooserConfigurations.configurations);
            const outputColumnChooserButton = `<span class="menu-btn padding-left-1 col-chooser-btn sif sif-column-chooser outputs"></span>`;
            this.compareTableSettings.groupHeaders.push({
                label: `<span class="nested-header-font">RESULTS ${outputColumnChooserButton}</span>`,
                type: 'RESULTS',
                colspan: outputColumnCount
            });
            this.compareTableSettings.outPutColumnStartIndex = 2 + inputsColumnCount;
        }
        this.compareTableSettings.subHeaders = [];
        if (outputColumnCount > 0) {
            this.compareTableSettings.subHeaders.push({
                label: '',
                type: '',
                colspan: 2
            });
            if (inputsColumnCount) {
                this.compareTableSettings.subHeaders.push({
                    label: '',
                    type: 'INPUTS',
                    colspan: inputsColumnCount
                });
            }
            if (this.multiSelectedScenarioIds.length === 1) {
                const baseScenario = this.getScenario(this.selectScenarioId);
                const compareScenario = this.getScenario(this.multiSelectedScenarioIds[0]);
                this.compareTableSettings.subHeaders.push({
                    label: `<span class="comparison-header">   ${compareScenario.name} <span class="sif sif-comparison"></span>    ${baseScenario.name} </span>`,
                    type: 'ScenarioComp1',
                    colspan: outputColumnCount,
                });
            } else {
                const baseScenario = this.getScenario(this.selectScenarioId);
                this.absolutColumnSelected();
                const absolutes = ['units', 'revenue', 'volume', 'equivalizedVolume', 'profit'];
                const absoluteField = absolutes.find(abs => this.metaData.outputConfigurations[(abs)] && this.metaData.outputConfigurations[(abs)]['showInSimulator']);
                if ( this.absoluteSelected && this.metaData.outputConfigurations[(absoluteField)] && this.metaData.outputConfigurations[(absoluteField)]['showAbsolutesIndices'] && !this.hasUnSelectedNonSampleFilter) {
                    this.multiSelectedScenarioIds.forEach(mId => {
                        const compareScenario = this.getScenario(mId);
                        this.compareTableSettings.subHeaders.push({
                            label: `<span class="comparison-header">${compareScenario.name}  <span class="sif sif-comparison"></span>  ${baseScenario.name} </span>`,
                            type: 'ScenarioComp1',
                            colspan: 1,
                        });
                    });
                } else {
                    this.compareTableSettings.subHeaders.push({
                        label: '',
                        type: 'ScenarioComp2',
                        colspan: 1,
                    });
                    this.multiSelectedScenarioIds.forEach(mId => {
                        const compareScenario = this.getScenario(mId);
                        this.compareTableSettings.subHeaders.push({
                            label: `<span class="comparison-header">${compareScenario.name}  <span class="sif sif-comparison"></span>  ${baseScenario.name} </span>`,
                            type: 'ScenarioComp1',
                            colspan: 2,
                        });
                    });
                }
            }
        }
    }

    constructColumnHeadersWhenFiltersEngaged(outputColumnCount): number {
        if (this.compareTableSettings.columns &&  this.compareTableSettings.columns.length===0) {
            const key = 'unitsShare';
            let columnHeader = `${this.metaData.outputConfigurations[key]['displayName']}`;
            this.compareTableSettings.columnHeaders.push(`<span class="right-align-headers"><span class="headers-align-right">${columnHeader}</span><br><span class="base-font">(Base)</span></b></span>`);
            const outputColObj = {
                name: this.conditionalColumns[key].name,
                displayName: columnHeader,
                type: 'text',
                data: `${this.conditionalColumns[key].name}_${this.selectScenarioId}`,
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.conditionalColumns[key].renderer,
                indexColumn: false
            };
            this.compareTableSettings.columns.push(outputColObj);
            outputColumnCount = outputColumnCount + 1;

            columnHeader = `${this.metaData.outputConfigurations[key]['displayName']}`;
            const CompareOutputColObj = {
                name: this.conditionalColumns[key].name,
                displayName: columnHeader,
                type: 'text',
                data: `${this.conditionalColumns[key].name}_${this.multiSelectedScenarioIds[0]}`,
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.conditionalColumns[key].renderer,
                indexColumn: false
            };

            this.compareTableSettings.columnHeaders.push(`<span class="right-align-headers"><span class="headers-align-right">${columnHeader}</span><br><span class="base-font">(Comparative)</span></b></span>`);
            this.compareTableSettings.columns.push(CompareOutputColObj);
            outputColumnCount = outputColumnCount + 1;
            columnHeader = `${this.metaData.outputConfigurations[key]['displayName']}`;
            const CompareOutputColObj1 = {
                name: this.conditionalColumns[key].name,
                displayName: columnHeader,
                type: 'text',
                data: `${this.conditionalColumns[key].name}_${this.multiSelectedScenarioIds[0]}_index`,
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.indexCellRenderer.bind(this.cellRenderers),
                indexColumn: true
            };
            this.compareTableSettings.columnHeaders.push(`<span class="right-align-headers"><span class="headers-align-right">${columnHeader}</span><br>Index</b></span>`);
            this.compareTableSettings.columns.push(CompareOutputColObj1);
            outputColumnCount = outputColumnCount + 1;
        }
        return outputColumnCount;
    }

    absolutColumnSelected(){
        const absolutes = ['units', 'revenue', 'volume', 'equivalizedVolume','profit'];
        const outputFields = ['units', 'unitsShare', 'unitsPromoShare', 'revenue', 'revenueShare', 'profit','equivalizedVolume', 'equivalizedVolumeShare', 'psShare'];
        const configType = this.appConstantsService.compareMultiSkuConfigColumnChooserConfigType;
        let columnChooserConfigurations = this.userConfigurationsService.getUserConfigurationsByType(configType);
        columnChooserConfigurations = columnChooserConfigurations ? columnChooserConfigurations : new UserConfigurations();
        columnChooserConfigurations.configurations = columnChooserConfigurations.configurations ? columnChooserConfigurations.configurations : {
            hideGroups: [],
            hideInputs: [],
            hideOutputs: []
        };
        const userConfigurations =  columnChooserConfigurations.configurations;
        const hideColList =  userConfigurations['hideOutputs'].filter((con)=>{
            return con.name.substr(con.name.length - 5, con.name.length) === 'Share' ? !con.showInColumn : (!con.showInColumn && !con.showIndices);
        }).map(it=>it.name);
        let filteredList = outputFields.filter(o1 => !hideColList.find(o2 => o1 === o2));
        filteredList = filteredList.filter(f=>this.metaData.outputConfigurations[(f)] && this.metaData.outputConfigurations[(f)]['showInSimulator']);
        const absoluteField = absolutes.find(abs => this.metaData.outputConfigurations[(abs)] && this.metaData.outputConfigurations[(abs)]['showInSimulator']);
        this.absoluteSelected = columnChooserConfigurations.configurations['hideOutputs'].length === 0 ? (this.hasUnSelectedNonSampleFilter ? false : (absoluteField ? true : false)) : absolutes.indexOf(filteredList[0]) >= 0;
    }

    initializeColumns(): void {
        const continuousPromoPriceRenderer = this.cellRenderers.continuousPromoPriceRenderer.bind(this.cellRenderers);
        const promoPriceDropdownRenderer = this.cellRenderers.promoPriceDropdownRenderer.bind(this.cellRenderers);
        const isContinuousPromo = this.metaData.promo === 'continuous';

        this.visibleColumns = {
            id: {
                name: 'id',
                type: 'numeric',
                data: 'skuId',
                readOnly: true,
                className: 'htRight',
                width: 56,
                index: 1,
                displayName: 'Id',
            },
            isSelected: {
                name: 'isSelected',
                type: 'checkbox',
                data: 'isSelected',
                readOnly: false,
                className: 'htCenter',
                width: 70,
                index: 0,
                displayName: 'selectAll'
            },
            reportingName: {
                name: 'reportingName',
                type: 'text',
                displayName: 'reportingName',
                data: 'reportingName',
                readOnly: true,
                className: 'ellipsis htLeft',
                width: 416,
                index: 2,
                renderer: this.cellRenderers.reportNameRenderer.bind(this.cellRenderers)
            },
        };
        this.conditionalColumns = {
            priceInput: {
                name: 'priceInput',
                type: 'numeric',
                data: 'priceInput',
                displayName: 'Regular Price',
                readOnly: false,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.priceInputRenderer.bind(this.cellRenderers)
            },
            priceInputRange: {
                name: 'priceInputRange',
                type: 'text',
                data: 'priceInputRange',
                displayName: 'Regular Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.priceRangeRenderer.bind(this.cellRenderers)
            },
            distribution: {
                name: 'distribution',
                type: 'text',
                data: 'distribution',
                readOnly: false,
                className: 'htRight',
                width: 104,
                displayName: 'Distribution',
                dropdownMenu: false,
                renderer: this.cellRenderers.distributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator
            },
            promoPrice: {
                name: 'promoPrice',
                type: isContinuousPromo ? 'numeric' : 'dropdown',
                data: isContinuousPromo ? 'promoPrice' : 'promoPriceText',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Promo Price',
                source: '',
                width: 124,
                isPromoHeader: true,
                renderer: isContinuousPromo ? continuousPromoPriceRenderer : promoPriceDropdownRenderer,
                validator: promoPriceCellValidator
            },
            promoPriceRange: {
                name: 'promoPriceRange',
                type: 'text',
                data: 'promoPriceRange',
                displayName: 'Promo Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoPriceRangeRenderer.bind(this.cellRenderers),
                isPromoHeader: true
            },
            specialPromoPrice: {
                name: 'specialPromoPrice',
                displayName: 'Special Promo Price',
                type: 'numeric',
                data: 'specialPromoPrice',
                readOnly: true,
                className: 'htRight',
                width: 130,
                isPromoHeader: true,
                renderer: this.cellRenderers.specialPromoPriceCellRenderer.bind(this.cellRenderers),
                validator: specialPromoPriceCellValidator
            },
            promoDistribution: {
                name: 'promoDistribution',
                type: 'numeric',
                data: 'promoDistribution',
                displayName: 'Time on Promo',
                readOnly: false,
                className: 'htRight',
                width: 110,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            },
            baseSize: {
                name: 'baseSize',
                type: 'numeric',
                data: 'baseSize',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Size',
                source: '',
                width: 124,
                renderer: this.cellRenderers.baseSizeRenderer.bind(this.cellRenderers),
                validator: sizeCellValidator
            },
            sizeRange: {
                name: 'sizeRange',
                type: 'text',
                data: 'sizeRange',
                displayName: 'Size Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.sizeRangeRenderer.bind(this.cellRenderers),
            },
            featurePrice: {
                name: 'featurePrice',
                type: 'numeric',
                data: 'featurePrice',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Feature Only Price',
                source: '',
                width: 124,
                isPromoHeader: true,
                renderer: this.cellRenderers.featurePriceRenderer.bind(this.cellRenderers),
                validator: featurePriceCellValidator
            },
            featureDistribution: {
                name: 'featureDistribution',
                type: 'numeric',
                data: 'featureDistribution',
                displayName: 'Time on Feature Only',
                readOnly: false,
                className: 'htRight',
                width: 110,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            },
            featurePriceRange: {
                name: 'featurePriceRange',
                type: 'text',
                data: 'featurePriceRange',
                displayName: 'Feature Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.featurePriceRangeRenderer.bind(this.cellRenderers),
                isPromoHeader: true
            },
            displayPrice: {
                name: 'displayPrice',
                type: 'numeric',
                data: 'displayPrice',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Display Only Price',
                source: '',
                width: 124,
                isPromoHeader: true,
                renderer: this.cellRenderers.displayPriceRenderer.bind(this.cellRenderers),
                validator: displayPriceCellValidator
            },
            displayDistribution: {
                name: 'displayDistribution',
                type: 'numeric',
                data: 'displayDistribution',
                displayName: 'Time on Display Only',
                readOnly: false,
                className: 'htRight',
                width: 110,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            },
            displayPriceRange: {
                name: 'displayPriceRange',
                type: 'text',
                data: 'displayPriceRange',
                displayName: 'Display Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.displayPriceRangeRenderer.bind(this.cellRenderers),
                isPromoHeader: true
            },
            featureAndDisplayPrice: {
                name: 'featureAndDisplayPrice',
                type: 'numeric',
                data: 'featureAndDisplayPrice',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Feature & Display Price',
                source: '',
                width: 124,
                isPromoHeader: true,
                renderer: this.cellRenderers.featureAndDisplayPriceRenderer.bind(this.cellRenderers),
                validator: featureAndDisplayPriceCellValidator
            },
            featureAndDisplayDistribution: {
                name: 'featureAndDisplayDistribution',
                type: 'numeric',
                data: 'featureAndDisplayDistribution',
                displayName: 'Time on Feature and Display',
                readOnly: false,
                className: 'htRight',
                width: 110,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            },
            featureAndDisplayPriceRange: {
                name: 'featureAndDisplayPriceRange',
                type: 'text',
                data: 'featureAndDisplayPriceRange',
                displayName: 'Feature & Display Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.featureAndDisplayPriceRangeRenderer.bind(this.cellRenderers),
                isPromoHeader: true
            },
            units: {
                name: 'units',
                displayName: 'Units',
                type: 'text',
                data: 'unit',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.unitRenderer.bind(this.cellRenderers)
            }, unitsShare: {
                name: 'unitsShare',
                displayName: 'unitsShare',
                type: 'text',
                data: 'unitsShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.unitShareRenderer.bind(this.cellRenderers)
            },
            unitsPromoShare: {
                name: 'unitsPromoShare',
                displayName: 'Units on Promo Share',
                type: 'text',
                data: 'unitsPromoShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.unitsPromoShareRenderer.bind(this.cellRenderers)
            },
            equivalizedVolume: {
                name: 'equivalizedVolume',
                displayName: 'equivalizedVolume',
                type: 'text',
                data: 'equivalizedVolume',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.equivalizedVolumeRenderer.bind(this.cellRenderers)
            }, equivalizedVolumeShare: {
                name: 'equivalizedVolumeShare',
                displayName: 'equivalizedVolumeShare',
                type: 'text',
                data: 'equivalizedVolumeShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.equivalizedVolumeShareRenderer.bind(this.cellRenderers)
            }, revenue: {
                name: 'revenue',
                displayName: 'revenue',
                type: 'text',
                data: 'revenue',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.revenueRenderer.bind(this.cellRenderers)
            }, revenueShare: {
                name: 'revenueShare',
                displayName: 'revenueShare',
                type: 'text',
                data: 'revenueShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.revenueShareRenderer.bind(this.cellRenderers)
            },
            psShare: {
                name: 'psShare',
                type: 'text',
                data: 'psShare',
                readOnly: true,
                className: 'htRight',
                displayName: 'psShare',
                width: 150,
                renderer: this.cellRenderers.preferenceShareRenderer.bind(this.cellRenderers)
            },
            profit: {
                name: 'profit',
                type: 'text',
                data: 'profit',
                readOnly: true,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Profit',
                source: '',
                width: 150,
                renderer: this.cellRenderers.profitRenderer.bind(this.cellRenderers)
            }, volume: {
                name: 'volume',
                displayName: 'volume',
                type: 'text',
                data: 'volume',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.volumeRenderer.bind(this.cellRenderers)
            }, volumeShare: {
                name: 'volumeShare',
                displayName: 'volumeShare',
                type: 'text',
                data: 'volumeShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.volumeShareRenderer.bind(this.cellRenderers)
            },

        };
    }

    constructPriceInputColumnHeaders(inputsColumnCount, configurations): number {
        for (const key in this.conditionalColumns) {
            if (!this.conditionalColumns[key].isPromoHeader && (this.metaData.inputConfigurations[key]
                && this.metaData.inputConfigurations[key]['showInSimulator'])) {
                if (key === 'priceInput' && this.metaData.hasPrice) {
                    this.conditionalColumns[key].validator = priceCellValidator;
                } else if (key === 'priceInput') {
                    this.conditionalColumns.priceInput.readOnly = true;
                }
                const inputColumnHeader = `${this.metaData.inputConfigurations[key]['displayName']}`;
                this.compareTableSettings.columnHeaders.push(`<span class="headers-align-right right-align-headers">${inputColumnHeader}</span>`);
                this.conditionalColumns[key].displayName = this.metaData.inputConfigurations[key]['displayName'];
                this.compareTableSettings.columns.push(this.conditionalColumns[key]);
                inputsColumnCount = inputsColumnCount + 1;
            }
        }
        return inputsColumnCount;
    }

    constructPromoInputColumnHeaders(inputsColumnCount, configurations): number {
        if (this.metaData && this.metaData.promo !== 'none') {
            let requiredHeaders = ['promoPrice', 'specialPromoPrice', 'promoPriceRange', 'promoDistribution'];
            if(this.metaData.hasCoeffsFnd) {
                const tradePromoColumns = ['featurePrice','featurePriceRange', 'featureDistribution', 'displayPrice', 'displayPriceRange', 'displayDistribution',
                    'featureAndDisplayPrice','featureAndDisplayPriceRange', 'featureAndDisplayDistribution'];
                requiredHeaders = requiredHeaders.concat(tradePromoColumns);
            }
            requiredHeaders.forEach(header => {
                if (this.metaData.inputConfigurations[header] && this.metaData.inputConfigurations[header]['showInSimulator']) {
                    const promoInputHeader = `${this.metaData.inputConfigurations[header]['displayName']}`;
                    this.compareTableSettings.columnHeaders.push(`<span class="headers-align-right right-align-headers">${promoInputHeader}</span>`);
                    this.conditionalColumns[header].displayName = this.metaData.inputConfigurations[header]['displayName'];
                    this.compareTableSettings.columns.push(this.conditionalColumns[header]);
                    inputsColumnCount = inputsColumnCount + 1;
                }
            });
        }
        return inputsColumnCount;
    }

    constructOutputColumnHeaders(outputColumnCount, configurations): number {
        this.absolutColumnSelected();
        for (const key in this.conditionalColumns) {
            if (this.metaData.outputConfigurations[key] && this.metaData.outputConfigurations[key]['showInSimulator']) {
                const absolutes = ['units', 'revenue', 'volume', 'equivalizedVolume','profit'];
                if (this.isSingleComparison) {
                    let columnHeader = `${this.metaData.outputConfigurations[key]['displayName']}`;
                  //  if ((this.hasUnSelectedNonSampleFilter && this.metaData.outputConfigurations[key]['showOnNonSampleFilterToggle']) || !this.hasUnSelectedNonSampleFilter) {
                        if ((absolutes.indexOf(this.conditionalColumns[key].name) >= 0 && !(this.metaData.outputConfigurations[key]['showAbsolutesIndices'])) || (absolutes.indexOf(this.conditionalColumns[key].name) === -1)) {
                            this.compareTableSettings.columnHeaders.push(`<span class="right-align-headers"><span class="headers-align-right">${columnHeader}</span><br><span class="base-font">(Base)</span></b></span>`);
                            const outputColObj = {
                                name: this.conditionalColumns[key].name,
                                displayName: columnHeader,
                                type: 'text',
                                data: `${this.conditionalColumns[key].name}_${this.selectScenarioId}`,
                                readOnly: true,
                                className: 'htRight',
                                width: 130,
                                renderer: this.conditionalColumns[key].renderer,
                                indexColumn: false
                            };
                            this.compareTableSettings.columns.push(outputColObj);
                            outputColumnCount = outputColumnCount + 1;

                            columnHeader = `${this.metaData.outputConfigurations[key]['displayName']}`;
                            const CompareOutputColObj = {
                                name: this.conditionalColumns[key].name,
                                displayName: columnHeader,
                                type: 'text',
                                data: `${this.conditionalColumns[key].name}_${this.multiSelectedScenarioIds[0]}`,
                                readOnly: true,
                                className: 'htRight',
                                width: 130,
                                renderer: this.conditionalColumns[key].renderer,
                                indexColumn: false
                            };

                            this.compareTableSettings.columnHeaders.push(`<span class="right-align-headers"><span class="headers-align-right">${columnHeader}</span><br><span class="base-font">(Comparative)</span></b></span>`);
                            this.compareTableSettings.columns.push(CompareOutputColObj);
                            outputColumnCount = outputColumnCount + 1;
                        }
                        columnHeader = `${this.metaData.outputConfigurations[key]['displayName']}`;
                        const CompareOutputColObj = {
                            name: this.conditionalColumns[key].name,
                            displayName: columnHeader,
                            type: 'text',
                            data: `${this.conditionalColumns[key].name}_${this.multiSelectedScenarioIds[0]}_index`,
                            readOnly: true,
                            className: 'htRight',
                            width: 130,
                            renderer: this.cellRenderers.indexCellRenderer.bind(this.cellRenderers),
                            indexColumn: true
                        };

                        this.compareTableSettings.columnHeaders.push(`<span class="right-align-headers"><span class="headers-align-right">${columnHeader}</span><br>Index</b></span>`);
                        this.compareTableSettings.columns.push(CompareOutputColObj);
                        outputColumnCount = outputColumnCount + 1;
                   // }
                } else {
                    if ((this.hasUnSelectedNonSampleFilter && this.metaData.outputConfigurations[key]['showOnNonSampleFilterToggle']) || !this.hasUnSelectedNonSampleFilter) {
                        let columnHeader = `${this.metaData.outputConfigurations[key]['displayName']}`;
                        if ((this.absoluteSelected && !(this.metaData.outputConfigurations[key]['showAbsolutesIndices'])) || absolutes.indexOf(this.conditionalColumns[key].name) === -1) {
                            this.compareTableSettings.columnHeaders.push(`<span class="right-align-headers"><span class="headers-align-right">${columnHeader}</span><br><span class="base-font">(Base)</span></b></span>`);
                            const outputColObj = {
                                name: this.conditionalColumns[key].name,
                                displayName: columnHeader,
                                type: 'text',
                                data: `${this.conditionalColumns[key].name}_${this.selectScenarioId}`,
                                readOnly: true,
                                className: 'htRight',
                                width: 130,
                                renderer: this.conditionalColumns[key].renderer,
                                indexColumn: false
                            };
                            this.compareTableSettings.columns.push(outputColObj);
                            outputColumnCount = outputColumnCount + 1;
                        }
                        this.multiSelectedScenarioIds.forEach(mId => {
                            columnHeader = `${this.metaData.outputConfigurations[key]['displayName']}`;
                            if ((this.absoluteSelected && !(this.metaData.outputConfigurations[key]['showAbsolutesIndices'])) || absolutes.indexOf(this.conditionalColumns[key].name) === -1) {
                                this.compareTableSettings.columnHeaders.push(`<span class="headers-align-right right-align-headers">${columnHeader}</span>`);
                                const outputColObj = {
                                    name: this.conditionalColumns[key].name,
                                    displayName: columnHeader,
                                    type: 'text',
                                    data: `${this.conditionalColumns[key].name}_${mId}`,
                                    readOnly: true,
                                    className: 'htRight',
                                    width: 130,
                                    renderer: this.conditionalColumns[key].renderer,
                                    indexColumn: false
                                };
                                this.compareTableSettings.columns.push(outputColObj);
                                outputColumnCount = outputColumnCount + 1;
                            }

                            columnHeader = `${this.metaData.outputConfigurations[key]['displayName']} Index`;
                            this.compareTableSettings.columnHeaders.push(`<span class="headers-align-right right-align-headers">${columnHeader}</span>`);
                            const outputColObj = {
                                name: this.conditionalColumns[key].name,
                                displayName: columnHeader,
                                type: 'text',
                                data: `${this.conditionalColumns[key].name}_${mId}_index`,
                                readOnly: true,
                                className: 'htRight',
                                width: 130,
                                renderer: this.cellRenderers.indexCellRenderer.bind(this.cellRenderers),
                                indexColumn: true
                            };
                            this.compareTableSettings.columns.push(outputColObj);
                            outputColumnCount = outputColumnCount + 1;
                        });
                    }
                }
            }
        }
        return outputColumnCount;
    }

    createRequiredOutPutFields(skuConfigs, obj, outputName, isIndexRequired, scenarioId, baseScenarioId): void {
        const key = `${outputName}_${scenarioId}`;
        obj[(key)] = skuConfigs.get(obj.skuId)[(outputName === 'units' ? 'unit' : outputName === 'unitsShare' ? 'unitShare' : outputName === 'unitsPromoShare' ? 'unitPromoShare' : outputName)];
        if (isIndexRequired) {
            const compareKey = `${outputName}_${scenarioId}`;
            const baseKey = `${outputName}_${baseScenarioId}`;
            const indexKey = `${outputName}_${scenarioId}_index`;
            obj[indexKey] = this.getIndexValue(obj[compareKey], obj[baseKey]);
        }
    }

    constructHotTableData(): void {
        this.hotData = [];
        const compareScenarioSkuConfigs = new Map();
        const baseScenarioSkuConfigs = new Map();
        this.scenarioSkuConfigs = [...this.multiSelectedScenarioIds, this.selectScenarioId].reduce((acc, scenarioId) => {
            acc[scenarioId] = this.scenarioService.generateSkuConfigs(this.modelRunSkus, this.scenarioInputs[scenarioId], this.scenarioResults[scenarioId]);
            return acc;
        }, {});
        if (this.multiSelectedScenarioIds.length > 0 && this.selectScenarioId && !this.enableCompare) {
            this.scenarioSkuConfigs[this.multiSelectedScenarioIds[0]].map(s => {
                compareScenarioSkuConfigs.set(s.skuId, Object.assign({}, s));
            });
            this.scenarioSkuConfigs[this.selectScenarioId].map(s => {
                baseScenarioSkuConfigs.set(s.skuId, Object.assign({}, s));
            });
            if (this.selectedInputScenarioId && this.hasScenarioForInputsSelected && !this.enableCompare) {
                this.runSkuConfigs = this.scenarioSkuConfigs[this.selectedInputScenarioId].map(s => {
                    return Object.assign({}, s);
                });
            }
        }
        this.runSkuConfigs.map((obj) => {
            const selectedDiscretePromo = obj.promotions && obj.promotions.length ? obj.promotions[(obj.promoDropdownIdx - 1)]
                : null;
            obj.promoPriceText = selectedDiscretePromo ? selectedDiscretePromo.text :
                obj.promotions && obj.promotions.length ? 'Special Price' : null;
            obj.specialPromoPrice = obj.specialPromoPrice ? obj.specialPromoPrice :
                obj.reportingBasePromo ? parseFloat(obj.reportingBasePromo) : obj.promoPriceMin;
            const requiredOutputs = ['units', 'unitsShare','unitsPromoShare', 'revenue', 'revenueShare', 'profit', 'profitShare', 'volume',
                'volumeShare', 'equivalizedVolume', 'equivalizedVolumeShare', 'psShare'];
            if (obj.skuId && !this.enableCompare) {
                if (this.multiSelectedScenarioIds.length > 0 && this.selectScenarioId) {
                    let skuConfigs = new Map();
                    this.scenarioSkuConfigs[this.selectScenarioId].map(s => {
                        skuConfigs.set(s.skuId, Object.assign({}, s));
                    });
                    requiredOutputs.forEach(output => {
                        this.createRequiredOutPutFields(skuConfigs, obj, output, false, this.selectScenarioId, this.multiSelectedScenarioIds[0]);
                    });
                    this.multiSelectedScenarioIds.forEach(sId => {
                        skuConfigs = new Map();
                        this.scenarioSkuConfigs[sId].map(s => {
                            skuConfigs.set(s.skuId, Object.assign({}, s));
                        });
                        requiredOutputs.forEach(output => {
                            this.createRequiredOutPutFields(skuConfigs, obj, output, true, sId, this.selectScenarioId);
                        });
                    });
                }
            }
            this.addGroupData(obj);
            this.hotData.push(obj);
        });
        if (this.selectScenarioId && !this.enableCompare) {
            this.calculateTotalPsShareForAllScenarios();
        }
    }

    calculateTotalPsShareForAllScenarios(): void {
        const baseScenarioSkuConfigs = this.scenarioSkuConfigs[this.selectScenarioId].map(s => {
            return Object.assign({}, s);
        });
        this.outPutFieldsTotal.set(this.selectScenarioId, 0);
        let psShareTotal = 0;
        baseScenarioSkuConfigs.forEach(skuConfig => {
            psShareTotal = !isEmpty(skuConfig['psShare']) ? psShareTotal + parseFloat(skuConfig['psShare']) : psShareTotal;
        });
        this.outPutFieldsTotal.set(this.selectScenarioId, psShareTotal);
        this.multiSelectedScenarioIds.forEach(sId => {
            const compareScenarioSkuConfigs = this.scenarioSkuConfigs[sId].map(s => {
                return Object.assign({}, s);
            });
            this.outPutFieldsTotal.set(sId, 0);
            psShareTotal = 0;
            compareScenarioSkuConfigs.forEach(skuConfig => {
                psShareTotal = !isEmpty(skuConfig['psShare']) ? psShareTotal + parseFloat(skuConfig['psShare']) :
                    psShareTotal;
            });
            this.outPutFieldsTotal.set(sId, psShareTotal);
        });
    }

    addGroupData(hotObject): void {
        const groups = hotObject.groups;
        this.skuGroups.forEach(group => {
            const matchedSkuGroup = groups.find(it => it.skuGroupId === group.skuGroupId);
            const itemGroupings = group.itemGroupings;
            const itemGrouping = itemGroupings.find(i => {
                return matchedSkuGroup && i.itemGroupingId === matchedSkuGroup.itemGroupingId;
            });
            /**
             * NOTE: this should not happen in real case scenario, we had to add this for our test cases
             * where the suGroup data do not match with the groups in sku config
             */
            hotObject[group.displayName] = itemGrouping ? itemGrouping.displayName : 'All Other';
        });
    }

    getIndexValue(compareValue, baseValue): any {
        if (isEmpty(compareValue) || isEmpty(baseValue) || (compareValue <= 0 || baseValue <= 0)) {
            return '0.0';
        }
        return `${parseFloat(compareValue) / parseFloat(baseValue)}`;
    }

    setInputsHighlightStatus(): void {
        const isContinuousPromo = this.metaData.promo === 'continuous';
        const baseScenarioConfigs = this.scenarioInputs[this.selectScenarioId];
        if (this.selectedInputScenarioId && this.selectedInputScenarioId !== '0' && this.selectScenarioId && baseScenarioConfigs) {
            const baseScenarioSkuConfigs = new Map();
            baseScenarioConfigs.map(s => {
                baseScenarioSkuConfigs.set(s.skuId, Object.assign({}, s));
            });
            let index = 0;
            this.compareTableSettings.columns.forEach(column => {
                if (index > this.compareTableSettings.inputsColumnStartIndex && index <= this.compareTableSettings.outPutColumnStartIndex) {
                    this.hotData.map(h => {
                        if (h.skuId > 0 && ['priceInputRange', 'promoPriceRange'].indexOf(column.name) < 0 && (column.name === 'promoPrice' ? isContinuousPromo : true)) {
                            const baseSkuConfig = baseScenarioSkuConfigs.get(h.skuId);
                            if (h[(column.name)] !== baseSkuConfig[(column.name)]) {
                                h[`${column.name}_difference`] = h[(column.name)] === baseSkuConfig[(column.name)] ? '' : h[(column.name)] > baseSkuConfig[(column.name)] ? 'high' : 'low';
                            }
                        }
                    });
                }
                index++;
            });
        }
    }

    reloadData(onChange = false): void {
        this.initializeColumns();
        this.setOutputsFilteredOnNonSampleFilterToggleTooltip();
        this.outPutFieldsTotal = new Map();
        this.hotData = [];
        this.compareTableSettings.columnHeaders = [];
        this.compareTableSettings.groupHeaders = [];
        this.compareTableSettings.columns = [];
        this.compareTableSettings.allowFiltersForHeaders = [1];
        this.compareTableSettings.groupColumnStartIndex = 0;
        this.compareTableSettings.inputsColumnStartIndex = 0;
        this.compareTableSettings.outPutColumnStartIndex = 0;
        this.constructMandatoryColumnHeaders();
        this.constructColumnHeaders();
        this.constructHotTableData();
        this.setInputsHighlightStatus();
        this.compareTableSettings.columns.forEach((column) => {
            column.readOnly = true;
        });
        this.dropdownChange.next({
            compareTableSettings: this.compareTableSettings,
            hotData: this.hotData,
            skuGroups: this.skuGroups,
            outPutFieldsTotal: this.outPutFieldsTotal,
            compareScenarioIds: this.multiSelectedScenarioIds,
            baseScenarioId: this.selectScenarioId,
            onChange
        });
    }

    activeGroupClick($event) {
        this.activeSkuGroupId = $event ? $event.skuGroupId.toString() : '';
    }
    columnChooserClick(userConfigurations) {
        this.userConfigurations = userConfigurations;
        this.initializeColumns();
        this.reloadData(true);
    }

    setOutputsFilteredOnNonSampleFilterToggleTooltip(): void {
        this.hasUnSelectedNonSampleFilter = this.modelRunService.unSelectedNonSameFilterExists(this.filters);
        this.outputsFilteredOnNonSampleFilterToggleTooltip = this.hasUnSelectedNonSampleFilter ? this.metaDataService.tooltipOnNonSampleFilterEngage(this.metaData) : null;
    }



    exportScenarioComparison(): void {
        this.uiBlockerService.block();
        const allScenarioIds = this.multiSelectedScenarioIds.map(scenarioId => scenarioId);
        allScenarioIds.push(this.selectScenarioId);
        this.scenarioService.exportCompareScenarios(this.metaData.projectId, this.metaData.modelRunId, this.selectScenarioId, this.multiSelectedScenarioIds, this.activeSkuGroupId,this.hasUnSelectedNonSampleFilter).subscribe((response) => {
            const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = `Scenario-comparison-${Date.now()}-export.xlsx`;
            anchor.href = url;
            anchor.click();
            this.uiBlockerService.unblock();
        }, () => {
            this.uiBlockerService.unblock();
            this.snackBarService.openErrorSnackBar('CompareScenarios export failed.');
        });
    }

    onFilterSelectionChange(filter: DropdownData<string>): void {
        this.updateNSize();
        this.setOutputsFilteredOnNonSampleFilterToggleTooltip();
        this.scenarioService.MODEL_RUN_POPULATION_CHANGED_SUBJECT.next(this.filters);
        this.resetPopulationFiltersStatusCheck();
        this.filterChanged = true;
        this.checkCompareEnable();
    }

    resetPopulationFiltersStatusCheck(): void {
        if (this.modelRun.runId) {
            this.hidePopulationFiltersReset = this.metaData.projectType.toLowerCase().trim() === "als";
            this.resetPopulationFiltersStatus = this.modelRun.selectedSegments !== this.modelRunService.getUserRunLevelSelectedSegments(this.modelRun)
                || this.modelRun.selectedSegments !== this.getSelectedSegments().join(',');
        }
    }


    updateNSize(): void {
        if (this.modelRun.runId) {
            this.modelRunService.getNSize(this.projectId, this.modelRunId, this.getSelectedSegments()).subscribe({
                next: (data: any) => {
                    const oneThirdOfTotalNSize = Math.floor((1 / 3) * this.totalNSize);
                    this.nSize = data.nSize;
                    if (this.nSize <= LOW_POPULATION_THRESHOLD) {
                        this.lowPopulation = true;
                        this.lowPopulationMsgType = 'danger';
                        this.lowPopulationMsg = '75 or less respondents selected. Results are likely unreliable.';
                    } else if (this.nSize > Math.min(LOW_POPULATION_THRESHOLD, oneThirdOfTotalNSize) && this.nSize < Math.max(LOW_POPULATION_THRESHOLD, oneThirdOfTotalNSize)) {
                        this.lowPopulation = true;
                        this.lowPopulationMsgType = 'warn';
                        this.lowPopulationMsg = 'Less than 1/3 of total sample selected. Use extra caution when interpreting results.';
                    } else {
                        this.lowPopulation = false;
                        this.lowPopulationMsgType = null;
                    }
                }
            });
        }
    }

    getSelectedSegments(): Array<number> {
        if (this.modelRun.runId) {
            return this.modelRunService.getSelectedSegments(this.modelRun, this.segments, this.filters);
        } else {
            return [-1];
        }
    }
}
