import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HotTableRegisterer} from '@handsontable/angular';
import {EnvironmentService} from '@app/services/environment.service';
import {SkuConfigTableCellRenderers} from 'src/app/utils/sku-config-table-cell-renderers';
import {MetaData} from "@app/models/meta-data.model";
import {forkJoin, Subject} from "rxjs";
import {ScenarioService} from "@app/services/scenario.service";
import {UiBlockerService} from "@app/services/ui-blocker.service";
import {Calibration} from "@app/models/calibration/calibration.model";
import {UtilService} from "@app/services/util.service";
import {SkuConfig} from "@app/models/sku-config.model";
import {GeneralSettingService} from "@app/services/general-setting.service";
import {promoSlopeValidator, yellowTagValidator} from '../../utils/sku-config.validator';
import {CalibrationService} from "@app/services/calibration.service";
import {SnackBarService} from "@app/components/snack-bar/snack-bar.service";
import {AppConstantsService} from "@app/services/app-constants.service";

@Component({
    selector: 'app-promotions-modal',
    templateUrl: './promotions-modal.component.html',
    styleUrls: ['./promotions-modal.component.scss']
})
export class PromotionsModalComponent implements OnInit {

    scenarioSkuConfigs: Record<string, Array<SkuConfig>>;
    cellRenderers: SkuConfigTableCellRenderers;
    projectId: number;
    modelRunId: string;
    metaData: MetaData;
    hotTableData: any;
    tableSettings: any;
    calibration: Calibration;
    numberOfPromotions: number;
    promoType: string;
    skus: any;
    yt_add = 0;
    disableApply = true;
    dataChanged = false;
    reloadTable: Subject<any> = new Subject<any>();


    constructor(private hotTableRegisterer: HotTableRegisterer,
                private router: Router,
                private route: ActivatedRoute,
                private environmentService: EnvironmentService,
                private scenarioService: ScenarioService,
                private uiBlockerService: UiBlockerService,
                private utilService: UtilService,
                private generalSettingService: GeneralSettingService,
                private calibrationService: CalibrationService,
                private snackBarService: SnackBarService,
                private appConstantsService: AppConstantsService) {
    }

    ngOnInit(): void {
        this.metaData = this.route.parent.snapshot.data.metaData;
        this.promoType = this.metaData.promo;
        this.calibration = this.route.snapshot.data.calibration;
        this.disableApply = !this.calibration.enableApply;
        this.dataChanged = this.calibration.enableApply;
        this.projectId = +this.utilService.getRouteParameter(this.route.snapshot, 'projectId');
        this.modelRunId = this.utilService.getRouteParameter(this.route.snapshot, 'modelRunId');
        const generalSetting = this.route.parent.snapshot.data.generalSetting;
        this.cellRenderers = this.generalSettingService.createSkuConfigTableCellRenderersInstance(generalSetting);
        this.skus = [];
        this.hotTableData = [];
        this.loadScenarioSkuConfigs();
    }

    loadScenarioSkuConfigs(): void {
        const scenarioIds = this.calibration.skus.map(it => it.scenarioId);
        const joinRequests = scenarioIds.reduce((requests, scenarioId) => {
            if (!requests[scenarioId]) {
                requests[scenarioId] = this.scenarioService.getSkuConfigs(this.projectId, this.modelRunId, scenarioId);
            }
            return requests;
        }, {});

        this.uiBlockerService.block();
        forkJoin(joinRequests)
            .subscribe((data: any) => {
                this.scenarioSkuConfigs = data;
                this.prepareHotTableData();
            });
    }

    prepareHotTableData(): void {
        this.prepareHotData();
        this.prepareHeaders();
        this.reloadPromotionConfigTable();
        this.uiBlockerService.unblock();
    }

    prepareHotData(): void {
        this.skus = [];
        this.hotTableData = [];
        this.calibration.skus.forEach((sku) => {
            const scenarioSkus = this.scenarioSkuConfigs[sku.scenarioId];
            const scenarioSku = scenarioSkus ? scenarioSkus.find(s => {
                return s.skuId === sku.skuId;
            }) : {};
            this.skus.push(scenarioSku);
        });

        this.numberOfPromotions = this.skus[0].promotions ? this.skus[0].promotions.length : 0;
        this.yt_add = this.promoType === 'both' ? 1 : 0;

        this.skus.forEach(sku => {
            const calibrationSku = this.calibration.skus.find(s => {
                return s.skuId === sku.skuId;
            });
            sku['promoSlope'] = calibrationSku.promotion ? calibrationSku.promotion['promoSlope'] : this.appConstantsService.PROMO_SLOPE_DEFAULT_VALUE;
            sku['yt_1'] = calibrationSku.promotion ? calibrationSku.promotion.yellowTags['yt_1'] : this.appConstantsService.YT_DEFAULT_VALUE;
            if (this.promoType === 'continuous') {
                sku['continuous_promo_hatch'] = !(sku['promoPriceMin'] && sku['promoPriceMin']);
            } else if (this.promoType !== 'none') {
                sku['continuous_promo_hatch'] = sku['continuousMap'] === -1;
                sku['discrete_promo_hatch'] = sku['promoMappedIdx'] === -1;
            }
            sku['promoType'] = this.promoType;
            if (this.promoType === 'discrete' || this.promoType === 'both') {
                for (let promotion = 1; promotion <= this.numberOfPromotions; promotion++) {
                    sku[`discretePromo${promotion}`] = sku.promotions[promotion - 1].text;
                    sku[`yt_${promotion + this.yt_add}`] = calibrationSku.promotion ? calibrationSku.promotion.yellowTags[`yt_${promotion + this.yt_add}`] : this.appConstantsService.YT_DEFAULT_VALUE;
                }
                const discretePromoList = [];
                  let count = 0;
                for (let promotion = 1; promotion <= this.numberOfPromotions; promotion++) {
                    let discretePromo = discretePromoList.find((dp) => {
                        return dp.value === sku.promotions[promotion - 1].map;
                    });
                    if (discretePromo) {
                        discretePromo.list.push(promotion);
                    } else {
                        discretePromo = {'value': sku.promotions[promotion - 1].map, list: [promotion]};
                        discretePromoList.push(discretePromo);
                        count++;
                    }
                }
                for (let promotion = 1; promotion <= count; promotion++) {
                    const discretePromo = discretePromoList[promotion - 1];
                    const discretePromoStartIndex = discretePromo.list[0];
                    discretePromo.list.forEach((discretePromoIndex) => {
                        if (discretePromoIndex !== discretePromoStartIndex) {
                            sku[`discretePromo${discretePromoStartIndex}`] = sku[`discretePromo${discretePromoStartIndex}`] + ' | ' + sku[`discretePromo${discretePromoIndex}`];
                            sku[`discretePromo${discretePromoIndex}`] = '';
                            sku[`discretePromo${discretePromoIndex}_hatch`] = true;
                            sku[`yt_${discretePromoIndex + this.yt_add}_hatch`] = true;
                        }
                    });
                }
            }
            this.hotTableData.push(Object.assign({}, sku));
        });
    }

    prepareHeaders(): void {
        this.tableSettings = {
            columns: [],
            columnHeaders: ['ID', 'Reporting Name', 'Promo<br></br>Slope'],
            nestedHeaders: [[{label: 'ITEMS', colspan: 2}, {label: 'PROMOTION ADJUSTMENTS', colspan: 3}],
                ['', '', '']],
            allowFiltersForHeaders: [1]
        };
        this.tableSettings.columns = [
            {
                name: 'id',
                type: 'numeric',
                data: 'skuId',
                readOnly: true,
                className: 'htRight',
                width: 40,
                displayName: 'Id'
            },
            {
                name: 'reportingName',
                type: 'text',
                displayName: 'reportingName',
                data: 'reportingName',
                readOnly: true,
                className: 'ellipsis htLeft',
                width: 420
            },
            {
                name: 'promoSlope',
                type: 'numeric',
                data: 'promoSlope',
                readOnly: false,
                className: 'htRight',
                width: 60,
                displayName: 'Promo<br></br>Slope',
                renderer: this.cellRenderers.promoSlopeRenderer.bind(this.cellRenderers),
                validator: promoSlopeValidator,
            }
        ];

        const continuousPromo = {
            name: 'continuousPromo',
            type: 'text',
            data: 'continuousPromo',
            readOnly: true,
            className: 'htRight',
            width: 150,
            renderer: this.cellRenderers.adjustPromotionsPromoPriceRangeRenderer.bind(this.cellRenderers)
        };
        const yellowTag = {
            name: 'yt_1',
            type: 'numeric',
            data: 'yt_1',
            readOnly: false,
            className: 'htRight',
            width: 60,
            renderer: this.cellRenderers.yellowTagRenderer.bind(this.cellRenderers),
            validator: yellowTagValidator,
        };
        const discretePromo = {
            name: 'DiscretePromo',
            type: 'text',
            data: 'discretePromo',
            readOnly: true,
            className: 'ellipsis htRight',
            width: 150,
            renderer: this.cellRenderers.discretePromoRenderer.bind(this.cellRenderers),
        };

        let promotionAdjustmentsColSpan = 1;
        const promoName = this.metaData.promo === 'both' ? 'Special Price' : 'Continuous Promo';

        if (this.metaData.promo === 'continuous' || this.metaData.promo === 'both') {
            this.tableSettings.columns.push(continuousPromo);
            this.tableSettings.columns.push(Object.assign({}, yellowTag));
            this.tableSettings.nestedHeaders[1].push({label: `${promoName}`, colspan: 2});
            this.tableSettings.columnHeaders.push('Promo Price');
            this.tableSettings.columnHeaders.push('yt_1');
            promotionAdjustmentsColSpan = 3;
        }

        if (this.metaData.promo === 'discrete' || this.metaData.promo === 'both') {
            promotionAdjustmentsColSpan = promotionAdjustmentsColSpan + this.numberOfPromotions * 2;
            let emptyColumnCount = 0;
            for (let promotion = 1; promotion <= this.numberOfPromotions; promotion++) {
                if (promotion > 1) {
                    const atLeastOnePresent = this.skus.find(sku => sku[`discretePromo${promotion}`] !== '');
                    if (!atLeastOnePresent) {
                        emptyColumnCount++;
                        continue;
                    }
                }
                const index = promotion - emptyColumnCount;

                discretePromo['name'] = discretePromo['data'] = `discretePromo${promotion}`;
                yellowTag['name'] = yellowTag['data'] = `yt_${promotion + this.yt_add}`;
                this.tableSettings.columns.push(Object.assign({}, discretePromo));
                this.tableSettings.columns.push(Object.assign({}, yellowTag));
                this.tableSettings.nestedHeaders[1].push({label: `Discrete Promo ${index}`, colspan: 2});
                this.tableSettings.columnHeaders.push('Promo Price');
                this.tableSettings.columnHeaders.push(`yt_${index + this.yt_add}`);
            }
        }
        this.tableSettings.nestedHeaders[0][1].colspan = promotionAdjustmentsColSpan;
        this.tableSettings.nestedHeaders.push(this.tableSettings.columnHeaders);

    }

    savePromotionsToSimulator(): void {
        this.uiBlockerService.block();
        this.calibrationService.savePromotionsToSimulator(this.projectId, this.modelRunId, this.hotTableData).subscribe((calibration: Calibration) => {
                this.disableApply = true;
                this.calibration = calibration;
                this.prepareHotTableData();
                this.uiBlockerService.unblock();
                this.dataChanged = false;
                this.snackBarService.openSuccessSnackBar('Promo adjustments applied successfully.');
            },
            err => {
                this.uiBlockerService.unblock();
                this.snackBarService.openErrorSnackBar(err.error.message);
            });
    }

    returnToCalibration(): void {
        this.router.navigate(['calibration'], {relativeTo: this.route.parent});
    }

    tableErrorEventHandler(validate: { disableApply: boolean }): void {
        this.disableApply = validate.disableApply;
    }

    tableDataChangeEventHandler(updatedTableData: any): void {
        this.hotTableData = updatedTableData;
        this.dataChanged = true;
    }

    reloadPromotionConfigTable(): void {
        setTimeout(() => {
            this.reloadTable.next();
        }, 0);
        this.uiBlockerService.unblock();
    }
}
