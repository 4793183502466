import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {EnvironmentService} from './../services/environment.service';
import {HttpClient} from '@angular/common/http';
import {ModelCacheUtil} from './../utils/model-cache-util';
import {UtilService} from './../services/util.service';
import {Calibration} from '../models/calibration/calibration.model';
import {AppConstantsService} from './../services/app-constants.service';
import {AuthProxyService} from "@app/services/auth-proxy.service";
import {SingleSelectDropdownOption} from "@app/components/single-select-dropdown/single-select-dropdown.component";
import {ReadFile} from "ngx-file-helpers";
import {AsyncTask} from "@app/models/async-task";
import {OptimizationReport} from "@app/models/optimization-report.model";

@Injectable({
    providedIn: 'root'
})
export class CalibrationService {

    /**
     * Cached list of optimization reports
     */
    private cache: ModelCacheUtil<Calibration>;

    constructor(private http: HttpClient,
                private environmentService: EnvironmentService,
                private utilService: UtilService,
                private appConstantsService: AppConstantsService,
                private authProxyService: AuthProxyService) {
        this.cache = new ModelCacheUtil<Calibration>();
    }

    fetch(projectId: number, modelRunId: string): Observable<Calibration> {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration');
        return this.http.get<Calibration>(url).pipe(map((calibration: Calibration) => {
            this.cache.append(calibration);
            return calibration;
        }));
    }

    create(projectId: number, modelRunId: string): Observable<Calibration> {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration');
        return this.http.post<Calibration>(url, {}).pipe(map((calibration: Calibration) => {
            this.cache.append(calibration);
            return calibration;
        }));
    }

    calibrateStep1(calibration: Calibration, avgItemPriceElasticity: number, skuToScenarioMapping: Array<Record<'skuId' | 'scenarioId', number | string>>, selectedSegments: Array<number>, runId: number): Observable<Calibration> {
        const projectId = calibration.projectId;
        const modelRunId = calibration.modelRunId;
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration/step1');
        return this.http.post<Calibration>(url, {
            avgItemPriceElasticity: avgItemPriceElasticity,
            targetType: calibration.targetType,
            skus: skuToScenarioMapping,
            selectedSegments: selectedSegments,
            runId: runId
        }).pipe(map((calibration: Calibration) => {
            this.cache.append(calibration);
            return calibration;
        }));
    }

    calibrateStep2(calibration: Calibration, data: Array<{ skuId; targetUnitShare; targetPriceElasticity }>, innovation: string, selectedSegments: Array<number>): Observable<Calibration> {
        const projectId = calibration.projectId;
        const modelRunId = calibration.modelRunId;
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, `calibration/step2?innovation=${innovation}`);
        return this.http.put<Calibration>(url, {
            skus: data,
            selectedSegments: selectedSegments
        }).pipe(map((calibration: Calibration) => {
            this.cache.append(calibration);
            return calibration;
        }));
    }

    calibrateSave(projectId: number, modelRunId: string, data: Array<{ skuId; tau }>,isDataChanged = false,calculateElasticity = false): Observable<Calibration> {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration/save')+`?isDataChanged=${isDataChanged}&calculateElasticity=${calculateElasticity}`;
        return this.http.put<Calibration>(url, data).pipe(map((calibration: Calibration) => {
            this.cache.append(calibration);
            return calibration;
        }));
    }

    delete(calibration: Calibration): Observable<Calibration> {
        const url = this.utilService.getModelRunUriBasedPath(calibration.projectId, calibration.modelRunId, 'calibration');
        return this.http.delete<Calibration>(url).pipe(map(() => {
            this.cache.remove(calibration);
            return null;
        }));
    }

    applyOptions(calibration: Calibration): Observable<Calibration> {
        const url = this.utilService.getModelRunUriBasedPath(calibration.projectId, calibration.modelRunId, 'calibration');
        return this.http.put<Calibration>(url, {avgItemPriceElasticity: calibration.avgItemPriceElasticity ? +calibration.avgItemPriceElasticity : null}).pipe(map((updatedCalibration) => {
            this.cache.append(updatedCalibration);
            return updatedCalibration;
        }));
    }

    validateAvgItemPriceElasticity(avgItemPriceElasticity: number): boolean {
        if ((avgItemPriceElasticity !== null && avgItemPriceElasticity !== undefined) && `${avgItemPriceElasticity}`.length > 0) {
            return !isNaN(avgItemPriceElasticity) && avgItemPriceElasticity >= -7 && avgItemPriceElasticity < 0;
        } else {
            return true;
        }
    }

    savePromotionsToSimulator(projectId: number, modelRunId: string, skuConfigs: any): Observable<any> {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration/savePromotions');
        return this.http.put<any>(url, {skuConfigs}).pipe(map((skuConfigs) => {
            return skuConfigs;
        }));
    }

    lock(projectId: number, modelRunId: string): Observable<Calibration> {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration/lock');
        return this.http.get<Calibration>(url).pipe(map((calibration: Calibration) => {
            this.cache.append(calibration);
            return calibration;
        }));
    }

    unlock(projectId: number, modelRunId: string): Observable<Calibration> {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration/unlock');
        return this.http.get<Calibration>(url).pipe(map((calibration: Calibration) => {
            this.cache.append(calibration);
            return calibration;
        }));
    }

    exportCalibrationData(projectId: number, modelRunId: string): Observable<any> {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration/export');
        return this.http.get(`${url}`, {
                responseType: 'arraybuffer'
            }
        );
    }

    /**
     * Returns true if calibration has started
     * */
    started(calibration: Calibration): boolean {
        return calibration && calibration.statusCode > 0;
    }

    /**
     * Returns true if calibration step 2 has started
     * */
    step2Started(calibration: Calibration): boolean {
        return calibration && calibration.statusCode === this.appConstantsService.CALIBRATION_STEP2_STARTED;
    }

    simulatingCalibration(calibration: Calibration): boolean {
        return calibration && calibration.saveStarted && !calibration.saveEnded;
    }

    releaseCalibrationLock(calibration: Calibration): Observable<Calibration> {
        if (calibration && calibration.lockedBy === this.authProxyService.user.userId) {
            return this.unlock(calibration.projectId, calibration.modelRunId);
        } else {
            return of(calibration);
        }
    }

    setupVolumeTypeOptions(calibration: Calibration): Array<SingleSelectDropdownOption> {
        return [
            {
                label: 'Unit Share',
                value: 'target_unit_share',
                selected: calibration.targetType === 'target_unit_share'
            },
            {
                label: 'EQV Share',
                value: 'target_eq_volume',
                selected: calibration.targetType === 'target_eq_volume'
            }
        ];
    }

    isTargetEqVolume(calibration: Calibration): boolean {
        return calibration.targetType === 'target_eq_volume';
    }

    getShareTargetLabel(calibration: Calibration): string {
        if (this.isTargetEqVolume(calibration)) {
            return 'EQV <br> Shares';
        }
        return 'Unit <br> Shares';
    }

    uploadWeeklyDataFile(calibration: Calibration, file: ReadFile): Observable<Calibration> {
        const projectId = calibration.projectId;
        const modelRunId = calibration.modelRunId;
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/calibration/uploadWeeklyDataFile`;
        const formData = new FormData();
        formData.append('file', file.underlyingFile);
        return this.http.post<Calibration>(url, formData);
    }

    /**
     * Method is use to download weekly data file.
     *
     * @param calibration
     */
    downloadWeeklyDataFile(calibration: Calibration): Observable<ArrayBuffer> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${calibration.projectId}/runs/${calibration.modelRunId}/calibration/downloadWeeklyDataFile`;
        return this.http.get(`${url}`, {
                responseType: 'arraybuffer'
            }
        );
    }

    forceUnlock(projectId: number, modelRunId: string): Observable<Calibration> {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration/forceUnlock');
        return this.http.get<Calibration>(url).pipe(map((calibration: Calibration) => {
            this.cache.append(calibration);
            return calibration;
        }));
    }

    getCalcVersion(projectId: number, modelRunId: string) {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration/getCalcVersion');
        return this.http.get(url);      
    }

    saveCalibrationSettings(projectId: number, modelRunId: string, data): Observable<Calibration> {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'calibration/saveCalibrationSettings');
        return this.http.post<Calibration>(url,data).pipe(map((calibration: Calibration) => {
            this.cache.append(calibration);
            return calibration;
        }));
    }

    getBadFitCount(sourceData, viewType): any {
        const badFitCountObject = {unitShareFitIndex: 0, unitShareFitDifference: 0, promoSplitFitIndex: 0};
        sourceData.forEach(sku => {
            const isMatchingView = (viewType === 'skuConfigTable-current' && !sku.isNewItem) || (viewType === 'skuConfigTable-new' && sku.isNewItem);
            if(isMatchingView && this.isBadFitUnitShareIndex(sku.precisionIndex)){
                badFitCountObject.unitShareFitIndex++;
            }
            if(isMatchingView && this.isBadFitUnitShareDifference(sku.precisionDifference)){
                badFitCountObject.unitShareFitDifference++;
            }
            if(isMatchingView && this.isBadFitPromoSplitIndex(sku.promoSplitFitIndex)){
                badFitCountObject.promoSplitFitIndex++;
            }
        });
        return badFitCountObject;
    }

    isBadFitUnitShareIndex(precisionIndex: number | null): boolean {
        return precisionIndex != null && precisionIndex != 0 && (precisionIndex * 100 > 110 || precisionIndex * 100 < 90);
    }

    isBadFitUnitShareDifference(precisionDifference: number | null){
        return precisionDifference != null && (precisionDifference * 100 < -0.2 || precisionDifference * 100 > 0.2);
    }

    isBadFitPromoSplitIndex(precisionIndex: number | null): boolean {
        return precisionIndex != null && precisionIndex != 0 && (precisionIndex * 100 > 110 || precisionIndex * 100 < 90);
    }

    getBadFitCountBadge(value: number): string{
        return `<div class="mat-badge mat-badge-large"><span class="badge-count mat-badge-content mat-badge-active">${value}</span></div>`;
    }

    getStep2CompletionPercentage(projectId: number, modelRunId: string) {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/calibration/getStep2CompletionPercentage`;
        return this.http.get(`${url}`);
    }

    stopCalibration(projectId: number, modelRunId: string):Observable<Calibration> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/calibration/stopStep2`;
        return this.http.get<Calibration>(`${url}`);
    }
    
    validateTauRhoColumns(hotTableData: any, invalidCells: any, view: string){
        const filterCondition = view === 'current' ? (it) => !it.isNewItem : (it) => it.hasCoRelatedSkus;
        const tableData = hotTableData.filter(filterCondition);
        tableData.forEach(sku => {
            const row = hotTableData.findIndex(it => it.skuId === sku.skuId && filterCondition);
            if(sku.tau !== undefined && sku.minTau && sku.maxTau && (sku.tau > sku.maxTau || sku.tau < sku.minTau) && row !== -1){
                if(invalidCells.has(row)){
                    invalidCells.get(row).add('tau');
                }else{
                    invalidCells.set(row, new Set<number>());
                    invalidCells.get(row).add('tau');
                }
            }else{
                if(invalidCells.has(row)){
                    invalidCells.get(row).delete('tau');
                    if (invalidCells.get(row).size === 0) {
                        invalidCells.delete(row);
                    }
                }
            }
            if(sku.rho1 !== undefined && sku.minRho && sku.maxRho && (sku.rho1 > sku.maxRho || sku.rho1 < sku.minRho) && row !== -1){
                if(invalidCells.has(row)){
                    invalidCells.get(row).add('rho1');
                }else{
                    invalidCells.set(row, new Set<number>());
                    invalidCells.get(row).add('rho1');
                }
            }else{
                if(invalidCells.has(row)){
                    invalidCells.get(row).delete('rho1');
                    if (invalidCells.get(row).size === 0) {
                        invalidCells.delete(row);
                    }
                }
            }
        });
    }

    saveForLaterCalibration(calibration: Calibration, data: Array<{ skuId; scenarioId; targetUnitShare; targetPriceElasticity; targetPercentageUnitsOnPromoShare; }>, innovation: string, selectedSegments: Array<number>): Observable<Calibration> {
        const projectId = calibration.projectId;
        const modelRunId = calibration.modelRunId;
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, `calibration/saveForLaterCalibration?innovation=${innovation}`);
        return this.http.put<Calibration>(url, {
            skus: data,
            selectedSegments: selectedSegments
        }).pipe(map((calibration: Calibration) => {
            this.cache.append(calibration);
            return calibration;
        }));
    }
}
